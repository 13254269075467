import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "./../components/layout"
import { TestFlightButton } from "./../components/testflightbutton"
import { AppStoreButton } from "./../components/appstorebutton"
import SEO from "../components/SEO/SEO"
import opticullIcon from "../images/opticull-icon.svg"
import petaPixelLogo from "../images/logos/pp-logo.png"
import fstoppersLogo from "../images/logos/fs-logo.png"
import diyPhotographyLogo from "../images/logos/diyp-logo.webp"
import thephoblographerLogo from "../images/logos/phblgrphr-logo.png"
import { Section, SectionImage, SectionText, SectionTextHeading, SectionTextCaption, SectionTextSubtitle, ImageFeatureCard, TestimonialCard } from "../components/section"
import { faCheck, faEnvelope, faKeyboard, faSdCard } from "@fortawesome/free-solid-svg-icons"
import appleSilicon from "../images/apple-silicon.png"
import appleCoreImage from "../images/apple-core-image.png"
import appleCoreML from "../images/apple-coreml.png"
import appleAppearance from "../images/apple-appearance.png"
import { FeatureCard } from "../components/featurecard"
import { StaticImage } from "gatsby-plugin-image"
import { Video } from "../components/video"
import workflowImport from "../images/opticull/workflow-import.png"
import workflowCull from "../images/opticull/workflow-cull.png"
import workflowShip from "../images/opticull/workflow-ship.png"
import { FaqList, FaqListItem } from "../components/ghost/faqs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord } from "@fortawesome/free-brands-svg-icons"
import { StarRatingFourAndHalf } from "../components/starrating"
import { ArticleFeed, ArticleFeedItem } from "../components/ghost/articlefeed"
import { enhanceBlogArticle } from "./help/cullai"

import chemaProfilePic from "../images/opticull/reviews/chema.jpg"
import felipeProfilePic from "../images/opticull/reviews/felipe.jpg"
import shyamProfilePic from "../images/opticull/reviews/shyam.jpg"

// markup
const OptiCullProductPage = ({ data, location }) => {

  const { updates } = data
  const { updatearticles } = updates

  const faqarticles = [{
      frontmatter: { title: "What is culling in photography?" },
      excerpt: "In photography, culling is the process of selecting final deliverable photos from all the photos taken during a photoshoot. Generally, culling is done before editing the photos so that only the photos that need to be delivered are edited, and editing effort is not wasted."
    },{
      frontmatter: { title: "Why should I use photo culling software?" },
      excerpt: "Good photo editing software is generally very slow when showing previews and does not usually have built-in smart features for culling. When you need to go through a ton of photos to select several hundred, those slow previews and lack of intelligent features will significantly slow you down. So, it makes sense to use purpose-built software for photo culling to save time drastically."
    }, {
      frontmatter: { title: "Why should I use AI photo culling software? Photo mechanic is fast enough." },
      excerpt: "Firstly, if you consider Photo Mechanic fast enough, please give OptiCull a try. You'll be surprised. Even with fast photo culling software, you still have to zoom in to check for focus and blinks, and reject low-quality photos. Moreover, you have to go back and forth while comparing duplicates to select a diverse set of final photos. AI photo culling software like OptiCull automatically tags low-quality photos with bad focus or blinks, groups duplicates together in scenes to compare side-by-side, and selects the best photos from each scene in automated mode. This drastically reduces your culling time. And even if you choose to review the results or opt for manual culling, you can sift through scenes instead of going through each photo one by one."
    }, {
      frontmatter: { title: "Why should I use OptiCull? How is it different from other photo culling softwares?" },
      excerpt: "OptiCull is the fastest photo culling software, both in terms of fastest RAW previews, face close-ups, and in terms of how fast you can review your shoot. It supports both automated and manual culling workflows. OptiCull magicully sorts duplicates into scenes, rejects blurry and blinking photos, and selects the best diversified keepers automatically based on your culling preferences. Even for manual culling, you can sift through duplicate scenes using innovative side-by-side comparison and zoom modes. For automated culling, all culling preferences are applied in real-time, you do not need to restart culling to see results. You can preview any scene to see the effects of culling preferences live to decide faster. Also, OptiCull will be the only option available on the Apple AppStore, which goes through rigorous Apple review for your security and privacy."
    }, {
      frontmatter: { title: "How does OptiCull use my photos?" },
      excerpt: "All your photos remain on your local machine at all times. All AI processing happens locally on the device. OptiCull does not need to and does not send your photos to any external servers. We deeply care for your privacy, so rest assured. DopeAI also comply with GDPR, please read our privacy policy to learn more."
    }, {
      frontmatter: { title: "What camera models are supported?" },
      excerpt: "OptiCull supports photos in RAW format that are supported by the host macOS version. For details, please check the Apple documentation for RAW support on your macOS version. Ideally, RAW photos that are viewable in Finder and Apple Preview app should be supported by OptiCull. Additionally, OptiCull supports JPG and RAW+JPG formats. Although unsupported RAW files are not officially supported, they may still work if they have large enough built-in JPG previews. As a short-term solution for working with macOS unsupported cameras, you can either turn on 1:1 inbuilt previews if your camera supports it, shoot in RAW+JPG, or convert them to DNG with 1:1 previews before importing."
    }, {
      frontmatter: { title: "What are the minimum system requirements to run OptiCull?" },
      excerpt: "OptiCull can run on any Mac running macOS 11.0 or later. To test beta builds, you will need macOS 12.0 as Apple TestFlight requires macOS 12.0 to run. OptiCull is super fast and less resource-demanding, so it will run smoothly even on a Mac with 8GB RAM."
    }, {
      frontmatter: { title: "What all editing apps OptiCull works with?" },
      excerpt: "OptiCull can ship selected photos directly to editing apps like Lightroom Classic, Lightroom CC, Capture One, Apple Photos and any other macOS app following standard file import guidelines from Apple."
    }, {
      frontmatter: { title: "Is Windows version planned?" },
      excerpt: "We regret to inform you that there are no plans for a Windows version at this time. Our current priority is to provide an exceptional user experience while adhering to the platform guidelines for macOS first."
    }, {
      frontmatter: { title: "Already purchased CullAi, how do I avail free upgrade to OptiCull Pro?" },
      excerpt: "If you bought CullAi Standard edition before June 7th, 2023, you are eligible to get a free upgrade to OptiCull Pro. Open the latest version of CullAi while connected to the internet, then open the latest version of OptiCull to enjoy OptiCull Pro. If you don't get upgraded, try restoring your original purchases in CullAi first."
    }
  ]

  return (
    <Layout>

      <SEO
        title="OptiCull - The fastest photo culling software | DopeAI"
        pathname={location.pathname}
        desc="OptiCull magicully sorts duplicates into scenes, rejects blurry and blinking photos, and selects the best keepers, all based on your culling preferences."
      />

      <div className="sticky top-0 py-2 bg-gray-50 bg-opacity-95 z-10 text-gray-500 text-base font-normal shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-row justify-between items-center">
          <nav>
            <Link className="text-xl text-gray-800 flex flex-row items-center" to="/opticull">
              <img className="flex-none w-8 h-8 -mt-1 rounded-lg object-cover" src={opticullIcon} alt="OptiCull Icon" title="OptiCull Icon with Camera Lense"/>
              <div className="ml-2">
                <span className="font-bold">OptiCull</span>
                {/* className="text-xl align-baseline text-white bg-blue-700 px-2 rounded" */}
                {/* <span> Beta</span> */}
              </div>
            </Link>
          </nav>
          <nav className="space-x-4 text-sm sm:text-base">
            {/* <a className="text-gray-600" href="https://apps.apple.com/app/apple-store/id6448657895?pt=122047473&ct=product-page&mt=8">Download on AppStore</a> */}
            <Link className="text-gray-600" to="/opticull#features">Features</Link>
            <Link className="text-gray-600" to="/opticull#pricing">Pricing</Link>
            <Link className="text-gray-600" to="/opticull#faqs">FAQs</Link>
            <Link className="text-gray-600" to="/opticull#updates">Updates</Link>
          </nav>
        </div>
      </div>

      <section className="py-16 lg:py-24 px-3 text-center">
        <h1 className="mx-auto sm:max-w-lg md:max-w-7xl text-4xl md:text-5xl lg:text-7xl xl:text-7xl font-bold">
          <span>The </span>
          <span className="">fastest</span><span> photo culling app </span><br></br>
          <span className="text-3xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-6xl leading-0 font-normal text-gray-700">for assisted and automated culling</span>
        </h1>
        <p className="mt-6 lg:mt-10 mx-auto max-w-4xl lg:max-w-5xl xl:max-w-6xl text-lg md:text-xl lg:text-2xl xl:text-2xl text-gray-500">
          <span>OptiCull provides the fastest RAW previews and <b className="magicull-blue">face closeups</b>. </span>
          <span>It sorts <b className="text-yellow-600">duplicates</b> into scenes </span>
          <span>and offers scene layouts with synced zoom modes for quick <b className="magicull-blue">side-by-side comparison</b>. </span>
          <span>It tags photos with blurry and blinking faces and provides <b className="magicull-blue">warnings</b> below each face for quick decisions. </span>
          <span>For rushed days, it has <b className="magicull-blue">magi-cull assessments</b> to automatically select best <b className="text-green-600">keepers</b> from each scene and <b className="text-red-600">reject</b> low quality ones based on your preferences.</span>
          {/* <span>OptiCull <b className="magicull-blue">magicully</b> sorts <b className="text-yellow-600">duplicates</b> into scenes, </span>
          <span><b className="text-red-600">rejects</b> photos with blurry and blinking faces, and selects the best </span>
          <span><b className="text-green-600">keepers</b> automatically based on your culling preferences. </span>
          <span>Even for manual culling, you can sift through duplicate scenes using innovative <b className="magicull-blue">side-by-side comparison</b> and zoom modes.</span> */}
          {/* <span>It is also the fastest RAW and close-ups viewer, with innovative side-by-side compare and zoom modes.</span> */}
        </p>
        {/* <TestFlightButton 
          className="mt-5 lg:mt-8" 
          product="opticull" location="product_page_hero"
          alt="Join Free OptiCull Beta on The Apple TestFlight" title="Join Free OptiCull Beta on The Apple TestFlight"
          href="https://testflight.apple.com/join/eq54bpp5">
        </TestFlightButton> */}
        <div className="mt-6 lg:mt-10 flex flex-col justify-center items-center space-y-3">
          <div className="flex flex-row space-x-4 items-center">
            <div className="flex flex-col">
              <div className="flex flex-row items-center space-x-2">
                <div className="text-xl text-gray-600 font-bold">4.2</div>
                <div><StarRatingFourAndHalf className="text-lg text-yellow-500"></StarRatingFourAndHalf></div>
              </div>
              <span className="text-sm text-gray-500">(40 ratings worldwide)</span>
            </div>
            <AppStoreButton className="" 
                alt="Download OptiCull on Apple App Store button" title="Download OptiCull on Apple App Store button" 
                product="opticull" location="product_page_hero" 
                href="https://apps.apple.com/app/apple-store/id6448657895?pt=122047473&ct=product-page&mt=8"
              >
            </AppStoreButton>
          </div>
          <div className="flex flex-row text-pink-400">
            <div>14-day free trial, no CC required, free plan.</div>
          </div>
        </div>

        {/* <p className="mt-3 mx-auto max-w-4xl text-lg md:text-xl lg:text-2xl text-gray-500">
          Let our <b className="text-gray-500">AI</b> bots do the mundane tasks of&nbsp;
          <b className="text-red-500">rejecting</b> blurred and blinks, grouping&nbsp;
          <b className="text-yellow-500">duplicates</b> into scenes and selecting best&nbsp; 
          <b className="text-green-500">keepers</b> from each scene</p> */}
        <div className="mt-10 max-w-7xl mx-auto">
          <StaticImage src="../images/opticull-hero-dark.png" 
            alt="OptiCull App Screenshot" title="OptiCull App Screenshot showing different panels and toolbar"
            blurredOptions={{ width: 256 }}>
          </StaticImage>
        </div>
      </section>

      <Section nogrid nopadding gridClassName="py-16">
        {/* <div className="mx-auto max-w-100 text-2xl">From the maker of CullAi, which brought duplicate grouping to culling apps</div> */}
        <p className="col-span-full -mt-8 mb-6 md:text-xl text-center text-gray-400">From the maker of CullAi, which introduced duplicate grouping back in 2020</p>
        <h2 className="text-xl font-extrabold text-gray-800 text-center mb-8">Featured In</h2>
        <div className="flex items-center justify-center mx-auto space-x-4 md:space-x-6 text-3xl sm:text-4xl mt-6">
          <div className="">
            <a target="_blank"
              href="https://petapixel.com/2023/06/07/opticull-uses-ai-to-assess-sort-and-rate-your-photos/">
              <img className="max-h-12"
                src={petaPixelLogo}
                alt="PetaPixel logo" title="Link to PetaPixel article - OptiCull Uses AI to Assess, Sort, and Rate Your Photos"/>
            </a>
          </div>
          <div className="">
            <a target="_blank"
              href="https://fstoppers.com/originals/cullai-mac-good-idea-has-way-go-538305">
              <img className="max-h-12"
                src={fstoppersLogo}
                alt="Fstoppers logo" title="Link to Fstoppers article - Cullai for Mac Is a Good Idea but Has a Way To Go"/>
            </a>
          </div>
          <div className="">
            <a target="_blank"
              href="https://www.thephoblographer.com/2023/07/21/opticull-review-is-an-ai-really-better-than-your-own-eyes/">
              <img className="max-h-12"
                src={thephoblographerLogo}
                alt="ThePhoblographer logo" title="Link to ThePhoblographer article - OPTICULL REVIEW – IS AN AI REALLY BETTER THAN YOUR OWN EYES?"/>
            </a>
          </div>
          <div className="">
            <a target="_blank"
              href="https://www.diyphotography.net/opticull-uses-ai-to-cull-out-duplicates-blurry-and-photos-with-blinking-people/">
              <img className="max-h-12"
                src={diyPhotographyLogo}
                alt="DIYPhotography logo" title="Link to DIYPhotography article - OPTICULL USES AI TO CULL OUT DUPLICATES, BLURRY AND PHOTOS WITH BLINKING PEOPLE"/>
            </a>
          </div>
        </div>
        
      </Section>

      <Section nogrid>
        <SectionTextCaption className="text-center">
          <svg class="h-12 mx-auto mb-3 text-gray-500" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
          </svg>
        </SectionTextCaption>
        <h2 className="mx-auto text-3xl lg:text-4xl font-bold text-gray-900 text-center mb-12">Pro photographers around the world love OptiCull</h2>

        <div className="grid md:grid-cols-12 gap-10 md:gap-6">
          <TestimonialCard author="Felipe" profilePic={felipeProfilePic} instagram="felipebocafoto" location="🇧🇷 Brazil"
          title="It's VERY fast!">
            I used Photo Mechanic previously, but your app is much better. I use the scenes for the formal photos on the cake table at the wedding, which are very similar, so the scenes mode helps a lot.
          </TestimonialCard>

          <TestimonialCard author="Chema" profilePic={chemaProfilePic} instagram="chema_photo" location="🇪🇸 Spain"
          title="Get back a lot of your time!!!">
            This is game changer honestly. Super fast preview and culling of your photos. Also improving it every time more and more!
          </TestimonialCard>

          <TestimonialCard author="Shyam" profilePic={shyamProfilePic} instagram="shyam.kurup.studios" location="🇲🇾 Malaysia"
          title="I highly recommend it">
            OptiCull is an amazing culling software. It has one of the fastest load speeds which helps save a lot of time.
          </TestimonialCard>

        </div>
      </Section>

      {/* <Section nopadding gridClassName="py-2"></Section> */}

      <Section id="features" gridClassName="pt-16 md:pt-24 lg:pt-40">

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextCaption className="text-blue-500">Ka-chow!</SectionTextCaption>
          <SectionTextHeading>Lightning fast RAW previews and AI analysis</SectionTextHeading>
          
          <SectionTextSubtitle>Never wait for previews or closeups to show up while reviewing your shoot.</SectionTextSubtitle>
          <SectionTextSubtitle>Start reviewing instantly while OptiCull <b className="text-gray-900">analyzes</b> photos at the speed of <b className="text-gray-900">1000 photos under a minute</b>.</SectionTextSubtitle>
          <SectionTextSubtitle>Video shows the lightning speed of OptiCull <b className="magicull-blue">in real time</b> on M1 MacBook Air. It gives similar viewing speeds even on Late 2013 Macbook Pro with 8GB RAM.</SectionTextSubtitle>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video className="video-container-16-10" src="p/opticull/fast-previews.mov"></Video>
        </SectionImage>

        {/* <p className="col-span-full order-3 text-right text-xs">*Video shows the lightning speed of OptiCull in <span className="font-bold text-gray-900">real time</span></p> */}

      </Section>

      <Section noborder>
        
        <SectionText className="md:order-2 md:pl-8">
          <SectionTextCaption className="text-yellow-500">Let's create a scene</SectionTextCaption>
          <SectionTextHeading>Groups duplicates into scenes in real-time</SectionTextHeading>
          <SectionTextSubtitle>Grouping duplicates makes it easy to compare them side-by-side and <b className="magicull-blue">select a diverse set</b> of final photos.</SectionTextSubtitle>
          <SectionTextSubtitle>You can adjust grouping preferences to group more photos together and reduce number of keepers.</SectionTextSubtitle>
        </SectionText>

        <SectionImage className="md:order-1">
          <Video className="video-container-16-10" src="p/opticull/grouping-duplicates.mov"/>
        </SectionImage>

      </Section>

      <Section noborder>

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextCaption className="text-green-500">Just like good old polaroid days</SectionTextCaption>
          <SectionTextHeading>Side-by-side compare and Synced Zoom modes</SectionTextHeading>
          <SectionTextSubtitle>Just select multiple photos or scenes to compare them side-by-side utilizing your screen real-estate.</SectionTextSubtitle>
          <SectionTextSubtitle>Use synced zoom modes to compare the <b className="magicull-blue">same face, all faces</b> or same regions in multiple photos at once.</SectionTextSubtitle>
          <SectionTextSubtitle>Navigate different faces in sync when in face zoom mode</SectionTextSubtitle>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video className="video-container-16-10" src="p/opticull/zoom-modes.mov"/>
        </SectionImage>

      </Section>

      <Section alternate noborder>

        <SectionText className="md:order-2 md:pl-8">
          <SectionTextCaption className="text-pink-500">One-take per scene</SectionTextCaption>
          <SectionTextHeading>Scene layout modes for faster review</SectionTextHeading>
          <SectionTextSubtitle>Use layout modes like <b className="magicull-blue">Scene Survey</b> mode to <b className="text-gray-900">sift through scenes</b> instead of photos one by one.</SectionTextSubtitle>
          <SectionTextSubtitle>It previews all photos in selected scene(s) by default.</SectionTextSubtitle>
          <SectionTextSubtitle>Just pan and zoom on one photo to compare different regions in one go, <b className="text-gray-900">without the need to go back and forth</b>.</SectionTextSubtitle>
        </SectionText>

        <SectionImage className="md:order-1">
          <Video className="video-container-16-10" src="p/opticull/synced-zoom.mp4"/>
        </SectionImage>

      </Section>

      <Section noborder>

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextCaption className="text-purple-500">Rushed days? no sweat..</SectionTextCaption>
          <SectionTextHeading><span className="">Magi-Cull</span> and Auto-Rate, personalized and instant</SectionTextHeading>
          <SectionTextSubtitle>Rejects photos with blurry and blinking subjects based on your preference.</SectionTextSubtitle>
          <SectionTextSubtitle>Selects best diversified keepers from each scene.</SectionTextSubtitle>
          <SectionTextSubtitle>Set <b className="text-gray-900">ratings you use</b> for keepers, duplicates and rejects, OptiCull will <b className="text-gray-900">automatically assign</b> those.</SectionTextSubtitle>
          <SectionTextSubtitle>You are always in control using <b className="text-gray-900">assessment overrides</b> while reviewing <b className="magicull-blue">magi-cull</b> results.</SectionTextSubtitle>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video className="video-container-16-10" src="p/opticull/auto-cull.mp4"/>
        </SectionImage>

      </Section>

      <Section alternate noborder>

        <SectionText className="md:order-2 md:pl-8">
          <SectionTextCaption className="text-red-500">AI doesn't know everything... just yet!</SectionTextCaption>
          <SectionTextHeading>Warnings for quick review</SectionTextHeading>
          <SectionTextSubtitle>Focus and Eye assessments are highlighted under each face for quick check without zooming in.</SectionTextSubtitle>
          <SectionTextSubtitle>Magi-Cull assigns blur and blink warnings to <b className="magicull-blue">keepers without better alternatives</b>.</SectionTextSubtitle>
          <SectionTextSubtitle>It also flags photos without people that it <b className="text-gray-900">could not assess</b>, for you to review.</SectionTextSubtitle>
        </SectionText>

        <SectionImage className="md:order-1">
          <Video className="video-container-16-10" src="p/opticull/warnings.mp4"/>
        </SectionImage>

      </Section>

      {/* <Section alternate noborder>

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextHeading>Save Metadata and Ship</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">Supports reading and writing both sidecar and inbuilt metadata. After reviewing and overwriting Magi-Cull assessments you can save metadata to files.</SectionTextSubtitle>
          <SectionTextSubtitle className="text-gray-500">And ship only keepers or all photos to your editing app like Lightroom and Capture One in just a click.</SectionTextSubtitle>
          <SectionTextSubtitle className="text-gray-500">If your editing app already has the photos imported, you can just sync metadata from files to get updated ratings.</SectionTextSubtitle>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video path="p/opticull/save-and-ship.mp4"/>
        </SectionImage>

      </Section> */}

      <Section nogrid>
        <SectionTextCaption className="text-center text-yellow-500">Ship it in a click</SectionTextCaption>
        <h2 className="mx-auto text-3xl lg:text-4xl font-bold text-gray-900 text-center mb-12">OptiCull is designed to be part of your workflow</h2>
        
        <div className="grid md:grid-cols-12 gap-10 md:gap-6">
          <ImageFeatureCard title="1. Import" image={workflowImport}>
            Import thousands of RAWs in <span className="text-gray-900">no time</span>, Just select source folder
          </ImageFeatureCard>
            
          <ImageFeatureCard title="2. Cull" image={workflowCull}>
            Cull your photoshoot in OptiCull using automated or manual culling
          </ImageFeatureCard>
            
          <ImageFeatureCard title="3. Edit" image={workflowShip}>
            Ship to your editing app like Lightroom or Capture One with just a click
          </ImageFeatureCard>
        </div>
        
      </Section>

      <Section nogrid>
        <SectionTextCaption className="text-center text-pink-500">Your photos, your rules</SectionTextCaption>

        <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 text-center mb-4 lg:mb-12">Privacy and Security</h2>

        <ul className="ml-4 list-disc text-lg max-w-xl sm:mx-auto">
          <li>All your <b className="text-gray-700">photos remain on your local machine</b> at all times. All AI processing happens locally on device. OptiCull does not need to and does not send your photos to any external servers.</li>
          <li>All the personally identifiable and anonymous data collected by DopeAI apps is declared in Apple app store listings and in our privacy policy. DopeAI is also GDPR compliant, please read our privacy policy for details</li>
          <li>OptiCull is available on the Apple App Store. Which means every version of our software is <b className="text-gray-700">reviewed and approved by Apple for your privacy and security</b>.</li>
          <li>This also means OptiCull has to run in <a href="https://developer.apple.com/documentation/security/app_sandbox" target="_blank"><b>Apple's Sandbox</b></a> on the device and it only has access to files and folders you select inside the app.</li>
          <li>Apps downloaded directly from the web in most cases don't run in the sandbox as it is not mandatory for non App Store Apps.</li>
          <li>We don't even need to know your email id, you can just login using Apple Sign In inside the app and choose to keep your email and name private.</li>
        </ul>
        <p></p>

      </Section>

      <Section nogrid>
        <SectionTextCaption className="col-span-full text-center text-blue-500">Unleash the cool beast</SectionTextCaption>
        <h2 className="col-span-full mx-auto text-3xl lg:text-4xl font-bold text-gray-900 text-center mb-12">Use your Mac's full potential</h2>

        <div className="grid md:grid-cols-12 gap-10 md:gap-6">
          <FeatureCard iconImage={appleSilicon}
            title="Apple Silicon">
            OptiCull is optimized for Apple silicon, taking full advantage of its incredible performance and efficiency.
          </FeatureCard>

          <FeatureCard icon={faKeyboard}
            title="Shortcuts">
            Mac friendly keyboard shortcuts for almost all actions lets you sift through quickly
          </FeatureCard>

          <FeatureCard icon={faSdCard}
            title="RAW">
            Official support for RAW formats supported by the host <a href="https://support.apple.com/en-in/HT213267"
              target="_blank">macOS</a> version and RAW+JPG. Also loads inbuilt JPG previews for unsupported cameras.
          </FeatureCard>

          <FeatureCard iconImage={appleAppearance}
            title="Appearance">
            Supports native macOS Light and Dark Appearance, Accent and Highlight color preferences
          </FeatureCard>

          <FeatureCard iconImage={appleCoreImage}
            title="Core Image">
            Core Image and ImageIO greatly speeds up reading inbuilt previews and generating missing RAW previews.
          </FeatureCard>

          <FeatureCard iconImage={appleCoreML}
            title="Core ML & Metal">
            The groundbreaking machine learning features in OptiCull are integrated using Core ML & Metal, which brings the best possible ML processing performance and efficiency.
          </FeatureCard>
        </div>

      </Section>

      <Section id="pricing" nogrid>
        <div className="max-w-7xl mx-auto">

          <div className="rounded-t-md bg-gray-800 border border-gray-800 py-8 px-4 md:px-6">
            <h2 className="text-xl font-extrabold text-gray-300 text-center mb-4">Pricing</h2>
            <p className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-gray-50 text-center mb-12">Show me the money!</p>
            {/* 🤑 */}

            <div className="grid md:grid-cols-2 gap-6 max-w-4xl mx-auto">

              <div className="">
                <div className="rounded-t-md bg-white py-8 px-6">
                  <div className="inline-block rounded-full py-1 px-3 bg-blue-50 text-blue-600 text-sm uppercase font-extrabold mb-4">Hobbyist</div>
                  <h3 className="text-gray-900 text-6xl font-extrabold mb-3">Free</h3>
                  <p>OptiCull Free version for Hobbyist Photographers.</p>
                </div>
                <div className="rounded-b-md bg-gray-50 text-gray-600 py-8 px-6">
                  <ul className="space-y-3">
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Lightning fast RAW previews.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Manual ratings and assessments
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Synced custom Zoom mode with lock
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Side-by-side compare multiple photos
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Persistant user assessment overrides
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Quick Rating, Assessment and Warning Filters
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Inbuilt and Sidecar metadata support
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Ship to editing Apps in a click
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      <span className="bg-blue-50 text-blue-600 text-sm uppercase font-extrabold">Unlimited Photos</span> and <span className="bg-blue-50 text-blue-600 text-sm uppercase font-extrabold">Unlimited Projects</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="">
                <div className="rounded-t-md bg-white py-8 px-6">
                  <div className="inline-block rounded-full py-1 px-3 bg-blue-50 text-blue-600 text-sm uppercase font-extrabold mb-4">Pro</div>
                  <div className="inline-block rounded-full py-1 px-3 bg-blue-50 text-blue-600 text-sm uppercase font-extrabold mb-4">Lifetime</div>
                  <div className="inline-block rounded-full py-1 px-3 bg-blue-50 text-blue-600 text-sm uppercase font-extrabold mb-4">Limited Seats</div>
                  <h3 className="text-gray-900 text-2xl mb-3">
                    <span className="line-through mr-1">$8/mo</span>
                    <span className="text-6xl font-extrabold">$79</span>
                    <span className="">.99</span>
                    {/* <span className="text-base text-gray-500">/mo Billed annually or $10 monthly</span> */}
                    {/* <span className="flex flex-col text-xs text-gray-500 ml-1"> */}
                    <span className="text-sm text-gray-500 ml-3">one-time purchase</span>
                    {/* <span>purchase</span> */}
                    {/* </span> */}
                  </h3>
                  <p>OptiCull Pro version for Professional Photographers.</p>
                  {/* <p className="text-yellow-600">Black Friday Sale from 22nd to 24th Nov 2023.</p> */}
                </div>
                <div className="rounded-b-md bg-gray-50 text-gray-600 py-8 px-6">
                  <ul className="space-y-3">
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Everything in OptiCull Free
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Face Closeups panel
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Scenes and Scene Layout modes
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Face and All Faces Zoom modes
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Focus and Eye assessments
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Magi-Cull assessments and Auto-Rate
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Warnings for quick review
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Background faces detection
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Facial Emotion Detection and Preference
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-16 flex flex-col justify-center items-center">
              <AppStoreButton className="" theme="white"
                alt="Download OptiCull on Apple App Store button" title="Download OptiCull on Apple App Store button" 
                product="opticull" location="product_page_bottom_cta" 
                href="https://apps.apple.com/app/apple-store/id6448657895?pt=122047473&ct=product-page&mt=8"
              >
              </AppStoreButton>
            </div>

            {/* <div className="mt-10 flex flex-col"> */}
              {/* <p className="w-full text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-50 text-center mb-8">Current Offers</p> */}
              {/* <div className="mx-auto max-w-xl">
                <StaticImage className="rounded-lg border-gray-600 border-2 max-w-md" src="../images/opticull/launch-offers-subs.png" 
                alt="OptiCull screenshot of home screen" title="OptiCull screenshot of home screen">
                </StaticImage>
              </div> */}
            {/* </div> */}

          </div>

          <div className="rounded-b-md bg-gray-50 border border-gray-300 py-8 px-4 md:px-6">

            <ul className="space-y-3 text-sm">
              <li>
                * This price is for Apple US Store and actual price in your native currency will be as per Apple In App Purchase price.
              </li>
              {/* <li>
                * The lifetime plan is now discontinued, and we have transitioned to a subscription-only pricing model.
              </li> */}
              {/* <li>
                * Percentage discounts on the app is calulated based on 12 months price for annual and 24 months price for the lifetime offers.
              </li> */}
              {/* <li>
                * If you have already purchased CullAi standard edition before 7th June 2023, you are eligible for free upgrade to OptiCull. Please check FAQs for how to avail this free upgrade.
              </li> */}
            </ul>

          </div>

        </div>

      </Section>

      <Section id="faqs" nogrid>
        <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900 text-center mb-8">Frequently Asked Questions</h2>
        <FaqList>
          {faqarticles.map(faq => (
            <FaqListItem key={faq.slug} faq={faq}></FaqListItem>
          ))}
          <article id="apple-sign-in-failure-faq" className="relative group px-4 py-6">
            <div className="post-wrapper">
              <header className="post-header">
                <h3 className="post-title text-lg text-gray-600 group-hover:text-gray-900 mb-2">Sign in with Apple not working?</h3>
              </header>
              <p className="post-excerpt text-gray-500">
                When you sign in with Apple on OptiCull, Apple displays the Apple ID login dialog. If you have already signed in to your Mac with your Apple ID, it just needs to confirm if you want to continue with that account. For the confirmation, it either uses Face ID, Touch ID, or your device passcode (in case Touch ID is not available or not configured). Users often try their Apple ID password instead of the system passcode on this prompt, resulting in sign-in failure.
              </p>
              <p className="post-excerpt mt-2 text-gray-500">
                Once you click the Sign in with Apple button, what you see is Apple's own sign-in flow, and OptiCull doesn't receive any of your credentials. You can find more information about this in "Use Sign in with Apple" section of the <a href="https://support.apple.com/en-in/102609" target="_blank">Apple's sign in guide</a>.
              </p>
            </div>
          </article>
          <article id="login-required-faq" className="relative group px-4 py-6">
            <div className="post-wrapper">
              <header className="post-header">
                <h3 className="post-title text-lg text-gray-600 group-hover:text-gray-900 mb-2">Why Sign in is required?</h3>
              </header>
              <p className="post-excerpt text-gray-500">
                We actually did not have Sign in to start with. But for some particular use cases this is the only recommended way by apple. Like <a href="https://developer.apple.com/documentation/storekit/in-app_purchase/original_api_for_in-app_purchase/subscriptions_and_offers/offering_a_subscription_across_multiple_apps" target="_blank">Sharing In App Purchases</a> in two or more apps when grand-fathering purchase in one app to another app.
              </p>
              <p className="post-excerpt mt-2 text-gray-500">
                We needed this while giving free upgrade to our existing CullAi customers. Considering we will be introducing more products sharing In App Purchases, Sign in is made mandatory.
              </p>
              <p className="post-excerpt mt-2 text-gray-500">
                You can always Sign in with Apple and keep your name and email private. But we totally respect your decision in case you decide not to Sign in.
              </p>
            </div>
          </article>
        </FaqList>
        <p className="mt-10 text-lg text-center">Get quick support on <a href="https://discord.gg/DQBFUub7ce" target="_blank"><FontAwesomeIcon className="text-indigo-500 hover:text-indigo-400" icon={faDiscord} size="lg" fixedWidth /> Discord</a> or write us at <a href="mailto:support@dopeai.tech"><FontAwesomeIcon className="text-gray-500 hover:text-gray-400" icon={faEnvelope} fixedWidth /> support@dopeai.tech</a>.</p>
      </Section>

      <Section id="updates" nogrid className="related-posts container medium">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900 text-center mb-4">Latest Updates</h2>
          <ArticleFeed>
            {updatearticles.map(article => {
              enhanceBlogArticle(article)
              return (
                <ArticleFeedItem key={article.id} article={article}></ArticleFeedItem>
              )
            })}
          </ArticleFeed>
        </div>
      </Section>

      <Section nogrid>
        <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900 text-center mb-8" id="roadmap">Feedback Hub</h2>
        <p className="mb-3 text-lg text-center">Open feedback hub from App to create new feature requests.</p>
        <iframe className="mx-auto w-full max-w-xl" height="1800px" src="https://feedback.opticull.dopeai.tech/?sortBy=trending" sandbox=""></iframe>
      </Section>

      <Section nogrid>
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 cta-background -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold text-gray-200 sm:text-4xl">
              Spend drastically less time culling
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-400">
              And more time doing what you love. OptiCull will cut your culling time up to 90%. Give it a try, we would love to hear your feedback.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                {/* <TestFlightButton 
                className="" 
                product="opticull" location="product_page_bottom_cta"
                alt="Join Free OptiCull Beta on The Apple TestFlight" title="Join Free OptiCull Beta on The Apple TestFlight"
                href="https://testflight.apple.com/join/eq54bpp5">
              </TestFlightButton> */}
              <div className="mt-6 lg:mt-10 flex flex-col justify-center items-center space-y-3">
                <AppStoreButton className="" theme="white"
                  alt="Download OptiCull on Apple App Store button" title="Download OptiCull on Apple App Store button" 
                  product="opticull" location="product_page_bottom_cta" 
                  href="https://apps.apple.com/app/apple-store/id6448657895?pt=122047473&ct=product-page&mt=8"
                >
                </AppStoreButton>
                <div className="flex flex-row text-pink-200">
                  <div>14-day free trial, no CC required.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <div className="absolute left-0 top-0 cta-image max-w-none bg-white/5 rounded md:rounded-lg border-none overflow-hidden">
              <StaticImage src="../images/opticull/cta-home-light.png" 
              alt="OptiCull screenshot of home screen" title="OptiCull screenshot of home screen"
              className="">
              </StaticImage>
            </div>
          </div>
        </div>
      </Section>

      <div id="demo" className="mx-auto max-w-7xl py-8 px-4 sm:px-6 lg:px-8 overflow-hidden border-t-2 border-gray-200">

        <h2 className="my-5 text-center text-3xl lg:text-4xl font-extrabold text-gray-900">
          OptiCull Demo
        </h2>

        <div className="my-8 md:col-span-7 md:order-1 yt-video-container">
          <iframe src="https://www.youtube.com/embed/mDINyl5eFpk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen>
          </iframe>
        </div>

      </div>

    </Layout>

  )
}

export default OptiCullProductPage

export const pageQuery = graphql`
query OptiCullProductPageQuery {
  updates: allMdx(
    filter: {
      frontmatter: {product: {eq: "cullai"}, category: {eq: "Updates"}},
      fields: { source: {eq: "blog"} }
    }
    sort: {fields: frontmatter___date, order: DESC}
    limit: 3
  ) {
    updatearticles: nodes {
      id
      excerpt(pruneLength: 128)
      timeToRead
      frontmatter {
        product
        category
        title
        date(formatString: "MMM DD, YYYY")
      }
      fields {
        source
      }
    }
  }
}
`
